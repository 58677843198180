import _ from "lodash";
import {
  FIELD_NAMES,
  REG_FIELDS,
  REG_KEY,
  TRegistrationPayload,
} from "./registrationModel";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import InputField from "../../Components/common/InputField";
import Button from "../../Components/common/Button";
import {
  INITIAL_STATE,
  getErrorObject,
  isInputValid,
} from "./registrationHelpers";
import { registerUser } from "../../api";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { updateCurrentUserData } from "../../store/UserSlice";
import { showModal } from "../../store/ModalSlice";

const RegistrationForm = () => {
  const [registrationDetails, setRegistrationDetails] =
    useState<REG_FIELDS>(INITIAL_STATE);
  const [error, setError] = useState<REG_FIELDS>(INITIAL_STATE);
  const email = useAppSelector((state) => state.auth.email);
  const dispatch = useAppDispatch();

  useEffect(() => {
    onChange(FIELD_NAMES.EMAIL as keyof REG_FIELDS, email);
  }, [email]);

  const onChange = (key: keyof REG_FIELDS, value: string) => {
    const updatedRegistrationDetails: REG_FIELDS = { ...registrationDetails };
    updatedRegistrationDetails[key] = value;
    setRegistrationDetails(updatedRegistrationDetails);

    //validate individual error
    updateErrorMessage(key, value);
  };

  const updateErrorMessage = (key: keyof REG_FIELDS, value: string) => {
    const errorMessage = isInputValid(value, key);
    const updatedError: REG_FIELDS = { ...error, [key]: errorMessage };
    setError(updatedError);
  };

  const getRequestPayload = (): TRegistrationPayload => {
    const paylaod: TRegistrationPayload = {
      userName: registrationDetails.name,
      contactNumber: registrationDetails.mobile,
      companyName: registrationDetails.company,
      companyUrl: registrationDetails.companyUrl,
      userRole: "admin", //TODO: update this. check logic for this one
      inviteToken: "", //TODO: update this. check logic for this one
    };
    return paylaod;
  };

  const userRegistration = async () => {
    try {
      dispatch(setLoader());
      const payload = getRequestPayload();
      const response = await registerUser(payload);
      if (response.data && response.data.data?.registered) {
        const userData = response.data.data;
        dispatch(updateCurrentUserData(userData))
        //TODO: redirect to dashboard if not happening automaticaly after store update
      } else {
        throw "Failed to register user";
      }
    } catch (error) {
      //TODO: Show registration error
      //TODO: Create error modal
      dispatch(showModal("Failed to register user"))
    } finally {
      dispatch(resetLoader());
    }
  };

  const onSubmit = async () => {
    const { isValid, errorObject } = getErrorObject(registrationDetails);
    setError(errorObject);
    if (isValid) {
      userRegistration();
    }
  };

  return (
    <div>
      {(_.keys(INITIAL_STATE) as REG_KEY[]).map((key: REG_KEY) => {
        return (
          <InputField
            label={key}
            key={key}
            onChangeHandler={(e) => onChange(key, e.target.value)}
            value={registrationDetails[key]}
            required
            disabled={_.isEqual(key, FIELD_NAMES.EMAIL)}
            errorMessage={error[key]}
            type={_.isEqual(key, FIELD_NAMES.MOBILE) ? "number" : undefined}
          />
        );
      })}
      <Button label="Register" onClick={onSubmit} />
    </div>
  );
};

export default RegistrationForm;
