import { MdLogout } from "react-icons/md"
import { logout } from "../../firebaseConfig";
import {AiOutlineLogout} from "react-icons/ai"

const LogoutComponent = ()=>{
    //TODO: show confirmation modal for logout
    return <div className="top-icon-container" onClick={logout}>
        {/* <MdLogout className={"nav-icon"}/> */}
        <AiOutlineLogout className={"nav-icon"}/>
    </div>
}

export default LogoutComponent;