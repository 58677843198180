import { Outlet } from "react-router-dom";
import LeftPanel from "./LeftPanel";
import "./wizard.css";
import TopPanel from "./TopPanel";
import TitleComponent from "./TitleComponent";

const Wizard = () => {
  return (
    <div className="wizard-container">
      <LeftPanel />
      <div className="wizard-inner-container">
        <TopPanel />
        <div className="outlet-container">
          <TitleComponent/>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Wizard;
