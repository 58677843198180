import { MdCall, MdImportContacts, MdMail } from "react-icons/md";
import InfoCard from "../../Components/common/InfoCard.tsx";

const ContactDetails = () => {
  return (
    <InfoCard title="Contact us" icon={<MdImportContacts />}>
      <div className="contact-inner-content">
        <div className="contact-section">
          <div className="contact-label">Support</div>
          <div className="contact-value"><MdMail/> support@episilia.com</div>
        </div>
        <div className="contact-section">
          <div className="contact-label">Tech</div>
          <div className="contact-value"><MdCall/> 8867574663</div>
        </div>
        <div className="contact-section">
          <div className="contact-label">Sales</div>
          <div className="contact-value"><MdCall/> 9972629799</div>
        </div>
      </div>
    </InfoCard>
  );
};

export default ContactDetails;
