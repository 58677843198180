import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { useEffect, useState } from "react";
import { getClusterList } from "../../api";
import { setClusterList } from "../../store/ClusterSlice";
import ClusterList from "./ClusterList";
import ClusterDetails from "./ClusterDetails";
import "./dashboard.css";

const Dashboard = () => {
  const { isLoaded } = useAppSelector((state) => state.clusterData);
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showDetailsPage, setShowDetailsPage] = useState<boolean>(false);

  const loadInitialData = async () => {
    try {
      dispatch(setLoader());
      const response = await getClusterList();
      if (response.data) {
        dispatch(setClusterList(response.data));
      }
    } catch (error) {
    } finally {
      dispatch(resetLoader());
    }
  };
  useEffect(() => {
    if (!isLoaded) {
      loadInitialData();
    }
  }, [isLoaded]);
  if (!isLoaded) {
    return <div>Loading</div>;
  }
  return (
    <div className="dashboard-container">
      {showDetailsPage && selectedId ? (
        <ClusterDetails
          clusterId={selectedId}
          setShowDetailsPage={setShowDetailsPage}
          setSelectedId={setSelectedId}
        />
      ) : (
        <ClusterList
          setSelectedId={setSelectedId}
          setShowDetailsPage={setShowDetailsPage}
        />
      )}
    </div>
  );
};

export default Dashboard;
