import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { MdPersonOutline } from "react-icons/md";

const ProfileIcon = () => {
  const photoUrl = useAppSelector((state) => state.auth.photoUrl);
  const navigate = useNavigate();
  const onProfileSelect = () => {
    navigate("profile");
  };
  return (
    <div className="top-icon-container" onClick={onProfileSelect}>
      <img className="profile-img" src={photoUrl ?? MdPersonOutline} />
    </div>
  );
};

export default ProfileIcon;
