import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

interface TModalState {
  showModal: boolean;
  message: string;
}

const initialState: TModalState = {
  showModal: false,
  message: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state,  action: PayloadAction<string>) => {
      state.showModal = true;
      state.message = action.payload
    },
    hideModal: (state) => {
      state.showModal = false;
      state.message = "";
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
