export const FIELD_NAMES = {
  NAME: "name",
  EMAIL: "email",
  MOBILE: "mobile",
  COMPANY: "company",
  COMPANY_URL: "companyUrl"
}
export interface REG_FIELDS{
  name: string;
  email: string;
  mobile: string;
  company: string;
  companyUrl: string;
}

export interface TRegistrationPayload { 
  companyName: string;
  companyUrl: string;
  contactNumber: string;
  userName: string;
  userRole: string;
  inviteToken: string;
}

export type REG_KEY = keyof REG_FIELDS;