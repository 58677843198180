import TableComponent from "../../Components/common/TableComponent";
import { useAppSelector } from "../../store/hooks";
import { TCluster } from "../../store/storeModel";

type TProps = {
  setSelectedId: Function;
  setShowDetailsPage: Function;
};

const ClusterList = (props: TProps) => {
  const { setSelectedId, setShowDetailsPage } = props;
  const { clusterList, isLoaded } = useAppSelector(
    (state) => state.clusterData
  );

  const onRowSelection = (data: TCluster) => {
    setSelectedId(data.id);
    setShowDetailsPage(true);
  };

  return (
    <div className="cluster-container">
      <div className="cluster-title">ClusterList</div>
      <TableComponent
        rowData={clusterList as any}
        columns={[]}
        onRowClicked={onRowSelection}
        additionalMargin={16}
        enableViewDetails={true}
      />
    </div>
  );
};

export default ClusterList;
