import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TCluster } from "./storeModel";
import _ from "lodash";

interface TClusterData {
  clusterList: TCluster[];
  isLoaded: boolean;
}

const initialState: TClusterData = {
  clusterList: [],
  isLoaded: false
};

const clusterSlice = createSlice({
  name: "clusterData",
  initialState,
  reducers: {
    resetClusterData: (state) => {
      state = _.cloneDeep(initialState);
      return state;
    },
    setClusterList: (state, action: PayloadAction<TCluster[]>) =>{
        state.clusterList = action.payload;
        state.isLoaded = true;
    }
  },
});

export const { resetClusterData, setClusterList } = clusterSlice.actions;

export default clusterSlice.reducer;
