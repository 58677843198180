import { useEffect, Fragment } from "react";
import {
  getCompanyInvitesList,
  getUserInvitesList,
  getUsersList,
} from "../../api";
import { useDispatch } from "react-redux";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import {
  TManageUserListTypes,
  TManageUserPayload,
  TUser,
} from "../../store/storeModel";
import { setInitialLoadedData } from "../../store/ManageUserSlice";
import { useAppSelector } from "../../store/hooks";
import UserList from "./UserList";
import "./manageUser.css";
import { TableType, UserListColumns } from "./manageUserUtils";

const ManageUsers = () => {
  const dispatch = useDispatch();
  const userDetails = useAppSelector((state) => state.manageUser);

  const onPageLoad = async () => {
    try {
      dispatch(setLoader());
      const promiseList = PromiseTypes.map((item) => item.apiCall());
      const response = await Promise.allSettled(promiseList);
      updateStore(response);
    } catch (error) {
      // TODO: Handle common page error
    } finally {
      dispatch(resetLoader());
    }
  };

  const updateStore = (responseList: PromiseSettledResult<any>[]) => {
    const storePayload: TManageUserPayload[] = responseList.map(
      (item: PromiseSettledResult<any>, index: number) => {
        return {
          isSuccess: item.status === "fulfilled",
          data: item.status === "fulfilled" ? item.value.data?.data : [],
          type: PromiseTypes[index].type,
        };
      }
    );
    dispatch(setInitialLoadedData(storePayload));
  };

  useEffect(() => {
    if (!userDetails.isExecuted) {
      onPageLoad();
    }
  }, []);

  //TODO: Handle error for each section separately
  //TODO: Render sections only after completion of loader function (api calls)
  return (
    <div className="manage-user-container">
      {!userDetails.isExecuted ? (
        <div>User data is being loaded please wait...</div>
      ) : (
        <Fragment>
          <UserList
            title="Users"
            data={userDetails.userList}
            isSuccess={userDetails.isUserListLoaded}
            columns={UserListColumns}
            type={TableType.USERS_LIST}
          />
          <UserList
            title="Invited users"
            data={userDetails.invitedUsers}
            isSuccess={userDetails.isInviteListLoaded}
            type={TableType.USER_INVITES}
          />
          <UserList
            title="Company invites"
            data={userDetails.companyInvites}
            isSuccess={userDetails.isCompanyInvitesLoaded}
            type={TableType.COMPANY_INVITES}
          />
        </Fragment>
      )}
    </div>
  );
};

export default ManageUsers;

//List of api calls to be made for manage user page
const PromiseTypes: { type: TManageUserListTypes; apiCall: Function }[] = [
  {
    type: TManageUserListTypes.UserList,
    apiCall: getUsersList,
  },
  {
    type: TManageUserListTypes.InviteList,
    apiCall: getUserInvitesList,
  },
  {
    type: TManageUserListTypes.CompanInviteList,
    apiCall: getCompanyInvitesList,
  },
];
