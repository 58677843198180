import LicenseDetails from "./LicenseDetails";
import OrganizationalDetails from "./OrganizationalDetails";
import UserDetails from "./UserDetails";
import "./profile.css";

const Profile = () => {
  return <div className="profile-container">
    <OrganizationalDetails/>
    <UserDetails/>
    <LicenseDetails/>
  </div>;
};

export default Profile;
