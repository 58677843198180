import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import InputField from "../../Components/common/InputField";
import Button, { TYPES } from "../../Components/common/Button";
import { useAppSelector } from "../../store/hooks";
import _ from "lodash";
import MESSAGES from "../../utils/messages";
import { TUpdateUserPayload } from "../../utils/commonModal";
import { updateUser } from "../../api";
import { useDispatch } from "react-redux";
import { TUserProfile } from "../../store/storeModel";
import { updateUserProfile } from "../../store/UserSlice";
import { resetLoader, setLoader } from "../../store/LoaderSlice";

type TProps = {
  cancelEditMode: Function;
};

const INITIAL_STATE: TEditData = {
  name: "",
  email: "",
  mobile: "",
};

const UserEditForm = (props: TProps) => {
  const { cancelEditMode } = props;
  const userProfile = useAppSelector((state) => state.user.userProfile);
  const [data, setData] = useState<TEditData>(INITIAL_STATE);
  const [error, setError] = useState<TEditData>(INITIAL_STATE);
  const [commonError, setCommonError] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    updateInitialState();
  }, [userProfile]);

  const updateInitialState = () => {
    if (userProfile) {
      const updatedData: TEditData = {
        name: userProfile.userName,
        email: userProfile.emailId,
        mobile: userProfile.contactNo,
      };
      setData(updatedData);
    }
  };

  const onFieldChange = (key: keyof TEditData, value: string) => {
    setData({ ...data, [key]: value });
    const errorMsg = isValueValid(value);
    setError({ ...error, [key]: errorMsg });
  };

  const updateUserDetailsAPI = async () => {
    const payload: TUpdateUserPayload = {
      contactNo: data.mobile,
      emailId: data.email,
      userName: data.name,
    };
    try {
      dispatch(setLoader());
      const { data } = await updateUser(payload);
      if (data && data.data) {
        dispatch(updateUserProfile(data.data));
        cancelEditMode();
      } else {
        throw MESSAGES.UPDATE_ERROR;
      }
    } catch (e) {
      setCommonError(MESSAGES.UPDATE_ERROR);
    } finally {
      dispatch(resetLoader());
    }
  };

  const onSubmit = () => {
    const { isValid, errorObj } = isValidData(data);
    setError(errorObj);
    if (isValid) {
      setCommonError("");
      updateUserDetailsAPI();
    } else {
      setCommonError(MESSAGES.FORM_ERROR);
    }
  };

  return (
    <div className="user-edit-container">
      {commonError && <div className="common-error">{commonError}</div>}
      {(_.keys(INITIAL_STATE) as TEditKey[]).map((key) => {
        return (
          <InputField
            label={_.startCase(key)}
            onChangeHandler={(e) => onFieldChange(key, e.target.value)}
            value={data[key]}
            disabled={key === "email"}
            errorMessage={error[key]}
          />
        );
      })}
      <div className="edit-btn-container">
        <Button
          label={"Cancel"}
          onClick={() => cancelEditMode()}
          type={TYPES.CANCEL}
        />
        <Button label={"Save"} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default UserEditForm;

type TEditData = {
  name: string;
  email: string;
  mobile: string;
};
type TEditKey = keyof TEditData;

const isValueValid = (value: string): string => {
  return _.isEmpty(value) ? MESSAGES.REQUIRED : "";
};

const isValidData = (
  data: TEditData
): { isValid: boolean; errorObj: TEditData } => {
  let isValid = true;
  const errorObj = { ...INITIAL_STATE };
  let key: TEditKey;
  for (key in data) {
    const errorMsg = isValueValid(data[key]);
    if (errorMsg) {
      isValid = false;
      errorObj[key] = errorMsg;
    }
  }
  return { isValid, errorObj };
};
