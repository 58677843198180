export type TLoginDetails = {
  uid: string;
  email: string;
  accessToken: string;
  photoUrl: string;
};

export type TUserProfile = {
  active: string;
  companyId: string;
  contactNo: string;
  createdAt: string;
  emailId: string;
  fbUid: string;
  id: number;
  updatedAt: string;
  userId: string;
  userName: string;
  userRole: string;
};

export type TCompanyProfile = {
  billingSetup: string;
  companyId: string;
  companyName: string;
  companyUrl: string;
  contactEmail: string;
  contactName: string;
  contactNo: string;
  createdAt: string;
  id: number;
  pgSetup: string;
  updatedAt: string;
};

export type TLicenseProfile = {
  active: string;
  companyId: string;
  createdAt: string;
  createdBy: string;
  id: number;
  licenseId: string;
  paidPlan: string;
  planId: string;
  planType: string;
  updatedAt: string;
  validFrom: string;
  validTill: string;
};

export type TCurrentUserPayload = {
  registered: boolean;
  subscribed: boolean;
  authenticated: boolean;
  companyProfile?: TCompanyProfile;
  userProfile?: TUserProfile;
  licenseProfile?: TLicenseProfile;
};

export interface TUser {
  active: string;
  companyid: string;
  contactno: string;
  createdat: string;
  emailid: string;
  fbuid: string;
  id: number;
  updatedat: string;
  userid: string;
  username: string;
  userrole: string;
}
export interface TManageUser {
  isUserListLoaded: boolean;
  isInviteListLoaded: boolean;
  isCompanyInvitesLoaded: boolean;
  userList: TUser[];
  invitedUsers: TUser[];
  companyInvites: TUser[]; //TODO: check exact format
  isExecuted: boolean;
}

export interface TManageUserPayload {
  isSuccess: boolean;
  data: TUser[];
  type: TManageUserListTypes;
}

export enum TManageUserListTypes {
  UserList = "UserList",
  InviteList = "InviteList",
  CompanInviteList = "CompanInviteList",
}


export interface TCluster {
  client_id: string,
  cloud: string,
  cluster_name: string,
  deployment: string,
  env: string,
  env_type: string,
  id: number,
  last_time: Date,
  last_ts: Date,
  release_version: string,
  start_time: Date,
  start_ts: Date,
  status: string,
  config: string,
}

export interface TMonthSummary { 
  id: number,
  companyId: string,
  monthName: string,
  invoiceRaised: string,
  invoiceId: string,
  paymentId: string,
  invoicePaid: string,
  yyyymm: number,
  logsInGB: number,
  billedLogsInGB: number,
  invoiceAmount: number,
  invoiceDate: Date,
  paidOn: Date,
  updatedAt: Date,
}

export interface TDailyUsage {
  
}

export interface TUsageSummary {
  monthSummary: TMonthSummary,
  yyyymm: number,

}