import "./button.css";

type TProps = {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: TYPES;
  classList?: string[]
};
const Button = (props: TProps) => {
  const { label, onClick, type = TYPES.PRIMARY, classList=[] } = props;
  return (
    <button className={`pulse-button ${type} ${classList.join(" ")}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;

export enum TYPES {
  PRIMARY = "primary",
  CANCEL = "cancel",
}
