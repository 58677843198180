import {
  MdSpaceDashboard,
  MdMonetizationOn,
  MdManageAccounts,
  MdPerson,
  MdSupportAgent,
  MdLocalOffer,
} from "react-icons/md";
import PATHS from "../../utils/path";
import Dashboard from "../Dashboard";
import ManageUsers from "../ManageUsers";
import Profile from "../Profile";
import Support from "../Support";
import Plans from "../Plans";
import Billing from "../Billing";

type TWizardConfig = {
  label: string;
  title: string;
  path: string;
  component: React.ReactElement;
  logo: React.ReactElement;
};
const WizardConfig: TWizardConfig[] = [
  {
    label: "",
    title: "Dashboard",
    path: `${PATHS.PULSE.path}`,
    component: <Dashboard />,
    logo: <MdSpaceDashboard className="nav-icon"/>,
  },
  {
    label: "billing",
    title: "Billing",
    path: `${PATHS.PULSE.path}/billing`,
    component: <Billing />,
    logo: <MdMonetizationOn className="nav-icon"/>,
  },
  {
    label: "manage-users",
    title: "Manage Users",
    path: `${PATHS.PULSE.path}/manage-users`,
    component: <ManageUsers />,
    logo: <MdManageAccounts className="nav-icon"/>,
  },
  {
    label: "plans",
    title: "Plans",
    path: `${PATHS.PULSE.path}/plans`,
    component: <Plans />,
    logo: <MdLocalOffer className="nav-icon"/>,
  },
  {
    label: "profile",
    title: "Profile",
    path: `${PATHS.PULSE.path}/profile`,
    component: <Profile />,
    logo: <MdPerson className="nav-icon"/>,
  },
  {
    label: "support",
    title: "Support",
    path: `${PATHS.PULSE.path}/support`,
    component: <Support />,
    logo: <MdSupportAgent className="nav-icon"/>,
  },
];

export default WizardConfig;
