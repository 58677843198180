import PlanCard from "./PlanCard";
import { PLAN_LIST, PLAN_TYPES } from "./planConfig";
import "./plans.css";

const Plans = () => {
  return (
    <div className="plan-section-container">
      <div className="plan-title">Plans for every enterprise!</div>
      <div className="plan-description">Plans for every enterprise</div>
      <div className="plan-card-section">
        {PLAN_LIST.map((planItem) => {
          return <PlanCard config={planItem}/>;
        })}
      </div>
    </div>
  );
};

export default Plans;
