import "./commonLoader.css";

interface TProps {
  show: boolean;
}
const CommonLoader = (props: TProps) => {
  const { show } = props;
  
  if (!show) return null;
  return (
    <div className="loader-container">
      <div className="loader-content">
        <div className="lds-hourglass"></div>
      </div>
    </div>
  );
};

export default CommonLoader;
