import { MdOutlineArrowRightAlt, MdQuestionAnswer } from "react-icons/md";
import InfoCard from "../../Components/common/InfoCard.tsx";
import Button from "../../Components/common/Button/index.js";

const Documentation = ()=>{
    return <InfoCard title="Have any query?" icon={<MdQuestionAnswer />}>
        <div className="doc-inner-content">
            <div className="doc-title">Refer to our knowledge base</div>
            <a href="https://docs.episilia.net/" target="_blank" className="doc-link">
                Go to Knowledge Base <MdOutlineArrowRightAlt size={20}/>
            </a>
        </div>
    </InfoCard>
}
export default Documentation;