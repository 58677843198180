import Moth from "../../assets/images/Final-Moth.png";
import WizardConfig from "./wizardConfig";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const LeftPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="left-panel-container">
      <div className="left-panel-logo">
        <img src={Moth} />
      </div>
      <div className="left-panel-nav-section">
        {WizardConfig.map((wizardItem) => {
          return (
            <div
              key={wizardItem.label}
              className={`icon-container ${
                _.isEqual(wizardItem.path, location.pathname)
                  ? "selected-icon"
                  : ""
              }`}
              onClick={() => navigate(wizardItem.label)}
            >
              {wizardItem.logo}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeftPanel;
