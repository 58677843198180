import { useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "./tableComponent.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import _ from "lodash";
import { useAppSelector } from "../../../store/hooks";
import { THEME } from "../../../utils/commonConstants";
import { ColumnMap, getViewDetailsColumnConfig } from "./tableUtils";
import { MdKeyboardArrowRight } from "react-icons/md";

//TODO: Add row on click call back which passes row data on click as value

type TProps = {
  rowData: { [key: string]: string }[];
  columns: string[];
  onRowClicked?: Function;
  additionalMargin?: number;
  enableViewDetails?: boolean;
};
const TableComponent = (props: TProps) => {
  const {
    rowData,
    columns = [],
    onRowClicked,
    additionalMargin = 0,
    enableViewDetails = false,
  } = props;
  const { theme } = useAppSelector((state) => state.theme);

  const [columnDefs, setColumnDefs] = useState<any>([]);

  const getColConfig = (col: string) => {
    return {
      field: col,
      headerName: ColumnMap[col] ?? _.startCase(col),
    };
  };

  const setColConfigList = () => {
    const tableCols = columns.length
      ? columns
      : rowData.length
      ? _.keys(rowData[0])
      : [];
    const tablecolDef = _.map(tableCols, getColConfig);
    if (enableViewDetails) {
      tablecolDef.push(getViewDetailsColumnConfig());
    }
    setColumnDefs(tablecolDef);
  };

  useEffect(() => {
    setColConfigList();
  }, [rowData, columns]);

  const getHeight = useCallback(() => {
    const height = rowData.length > 8 ? 400 : rowData.length * 42 + 48;
    return height + additionalMargin;
  }, [rowData]);

  return (
    <div
      className={`ag-theme-alpine${
        theme === THEME.LIGHT ? "" : "-dark"
      } table-container`}
      style={{ height: getHeight() }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        onRowClicked={onRowClicked ? (e) => onRowClicked(e.data) : undefined}
      ></AgGridReact>
    </div>
  );
};

export default TableComponent;
