import { useState, Fragment } from "react";
import Button from "../../Components/common/Button";
import CommonModal from "../../Components/common/CommonModal";
import SelectField from "../../Components/common/SelectField";
import {
  TInviteCompanyPayload,
  TInviteCompanyPayloadKey,
  USER_ROLES,
} from "./manageUserUtils";
import InputField from "../../Components/common/InputField";
import _ from "lodash";
import MESSAGES from "../../utils/messages";
import { useAppDispatch } from "../../store/hooks";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { inviteCompany } from "../../api";
import { showModal } from "../../store/ModalSlice";
import { updateInviteList } from "../../store/ManageUserSlice";

const INITIAL_STATE: TInviteCompanyPayload = {
  companyId: "",
  userRole: USER_ROLES[0],
  validDomain: "",
};

const INITIAL_ERROR_STATE: TInviteCompanyPayload = {
  companyId: "",
  userRole: "",
  validDomain: "",
};

const InviteCompany = () => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [companyDetails, setCompanyDetails] =
    useState<TInviteCompanyPayload>(INITIAL_STATE);
  const [errorObj, setErrorObj] =
    useState<TInviteCompanyPayload>(INITIAL_ERROR_STATE);
  const dispatch = useAppDispatch();

  const onCompanyDetailUpdate = (
    key: TInviteCompanyPayloadKey,
    value: string
  ) => {
    const updatedState: TInviteCompanyPayload = {
      ...companyDetails,
      [key]: value,
    };
    setCompanyDetails(updatedState);
    const errorMsg = validateValue(key, value);
    setErrorObj({ ...errorObj, [key]: errorMsg });
  };

  const isFormValid = () => {
    const updatedErrorObj: TInviteCompanyPayload = { ...INITIAL_ERROR_STATE };
    let key: TInviteCompanyPayloadKey;
    let isValid = true;
    for (key in companyDetails) {
      let msg = validateValue(key, companyDetails[key]);
      if (msg) {
        updatedErrorObj[key] = msg;
        isValid = false;
      }
    }
    setErrorObj(updatedErrorObj);
    return isValid;
  };

  const onSubmit = async () => {
    const isValid = isFormValid();
    if (isValid) {
      try {
        dispatch(setLoader());
        setShowInviteModal(false);
        const response: any = await inviteCompany(companyDetails);
        if (!response.data.success) {
          throw "Failed to send invitation";
        }
        //TODO: update company invite
        // await dispatch(updateInviteList());
        dispatch(
          showModal(response.data.message ?? "Invitation sent successfully!!!")
        );
      } catch (e) {
        dispatch(showModal("Failed to send invitation"));
      } finally {
        dispatch(resetLoader());
      }
    }
  };

  return (
    <Fragment>
      <Button
        label={"+ Invite company"}
        onClick={() => setShowInviteModal(true)}
        classList={["invite-btn"]}
      />
      <CommonModal
        showModal={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      >
        <div>
          <div className="invite-user-title">Invite Company</div>
          <InputField
            label={"Company Id"}
            onChangeHandler={(e) =>
              onCompanyDetailUpdate("companyId", e.target.value)
            }
            value={companyDetails.companyId}
            errorMessage={errorObj.companyId}
          />
          <SelectField
            label={"User role"}
            onChangeHandler={(e) =>
              onCompanyDetailUpdate("userRole", e.target.value)
            }
            value={companyDetails.userRole}
            options={USER_ROLES}
            errorMessage={errorObj.userRole}
          />
          <InputField
            label={"Company Domain"}
            onChangeHandler={(e) =>
              onCompanyDetailUpdate("validDomain", e.target.value)
            }
            value={companyDetails.validDomain}
            errorMessage={errorObj.validDomain}
          />
          <Button label={"Send Request"} onClick={onSubmit} />
        </div>
      </CommonModal>
    </Fragment>
  );
};

export default InviteCompany;

const validateValue = (
  key: TInviteCompanyPayloadKey,
  value: string
): string => {
  let msg = "";
  if (_.isEmpty(value)) {
    msg = MESSAGES.REQUIRED;
  }
  return msg;
};
