import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import CommonLoader from "./Components/common/CommonLoader";
import Login from "./Containers/Login";
import { useAppSelector } from "./store/hooks";
import PrivateRoute from "./Components/common/PrivateRoute";
import Registration from "./Containers/Registration";
import PageNotFound from "./Containers/PageNotFound";
import PATHS from "./utils/path";
import { Fragment } from "react";
import Wizard from "./Containers/Wizard";
import WizardConfig from "./Containers/Wizard/wizardConfig";
import Payment from "./Containers/Payment";
import MessageModal from "./Components/common/MessageModal";

function App() {
  const { showLoader, initialLoader } = useAppSelector((state) => state.loader);
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={`App ${theme}`}>
      {initialLoader ? (
        <CommonLoader show={initialLoader} />
      ) : (
        <Fragment>
          <CommonLoader show={showLoader} />
          <MessageModal/>
          <BrowserRouter>
            <Routes>
              <Route path={PATHS.LOGIN.label} element={<Login />}></Route>
              <Route
                path={PATHS.REGISTER.label}
                element={
                  <PrivateRoute path={PATHS.REGISTER.label}>
                    <Registration />
                  </PrivateRoute>
                }
              />
              <Route
                path={PATHS.PULSE.label}
                element={
                  <PrivateRoute path={PATHS.PULSE.label}>
                    <Wizard />
                  </PrivateRoute>
                }
              >
                {WizardConfig.map((wizardItem, index) => {
                  return (
                    <Route
                      key={wizardItem.label}
                      index={!index}
                      path={index ? wizardItem.label : undefined}
                      element={wizardItem.component}
                    />
                  );
                })}
                 <Route
                      key={"payment"}
                      path={`${PATHS.PULSE.path}/payment`}
                      element={<Payment/>}
                    />
              </Route>
              <Route path="*" element={<PageNotFound />}></Route>
            </Routes>
          </BrowserRouter>
        </Fragment>
      )}
    </div>
  );
}

export default App;
