import LogoutComponent from "./LogoutComponent";
import ThemeComponent from "./ThemeComponent";
import ProfileIcon from "./ProfileIcon";
import LicenseIdComponent from "./LicenseIdComponent";

const TopPanel = () => {
  return (
    <div className="top-panel-container">
      <div className="top-license-wrapper">
        <LicenseIdComponent />
      </div>
      <div className="top-icons-wrapper">
        <ThemeComponent />
        <LogoutComponent />
        <ProfileIcon />
      </div>
    </div>
  );
};

export default TopPanel;
