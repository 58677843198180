import { useAppSelector } from "../../store/hooks";
import DetailsCard from "./DetailsCard";

const ORG_FIELDS = [
  "companyName",
  "companyId",
  "companyUrl",
  "contactNo",
  "createdAt",
  "updatedAt"
];
const OrganizationalDetails = () => {
  const companyProfile = useAppSelector((state) => state.user.companyProfile);
  return (
    <div className="org-details-container">
        <div className="profile-title">Organization Details</div>
      {companyProfile && (
        <DetailsCard data={companyProfile} fields={ORG_FIELDS} />
      )}
    </div>
  );
};

export default OrganizationalDetails;
