import { setPageTheme } from "../../store/ThemeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { THEME } from "../../utils/commonConstants";
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import _ from "lodash";

const ThemeComponent = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const toggleTheme = () => {
    const newTheme = _.isEqual(theme, THEME.DARK) ? THEME.LIGHT : THEME.DARK;
    dispatch(setPageTheme(newTheme));
  };

  return (
    <div onClick={toggleTheme} className="top-icon-container">
      {_.isEqual(theme, THEME.LIGHT) ? <MdDarkMode className="nav-icon"/> : <MdOutlineLightMode className="nav-icon"/>}
    </div>
  );
};

export default ThemeComponent;
