import { MouseEvent } from "react";
import Button from "../../Components/common/Button";
import "./payment.css";
import { cashfree } from "../../utils/cashfreeConfig";

const Payment = () => {
  const onCheckOut = () => {
    let checkoutOptions = {
      paymentSessionId:
        "session_Ej6sPVDFXJ6D_DM-2FHF_577gNpnvYeodoCdIG6GmYlu3lvfk_DYrR2inbtAM5tdPuFXmYhDEsYRISOwiXO_cpwh4NhRHReEvHSMjjdy-mnL",
      returnUrl: "http://localhost:3000/pulse/payment?myorder={order_id}",
    };

    cashfree.checkout(checkoutOptions).then(function (result: any) {
        console.log("hello")
      console.log(result);
      if (result.error) {
        alert(result.error.message);
      }
      if (result.redirect) {
        console.log("Redirection");
      }
    });
  };

  return (
    <div className="payment-container">
      <div className="pay-title">Make your payment here!</div>
      <div className="pay-card-container">
        <div className="pay-card">
          <div className="pay-card-title">Purchase On-prem Edition</div>
          <div className="pay-amt">$ 150</div>
          <div>
            <Button label={"Pay"} onClick={onCheckOut} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
