import { useState } from "react";
import MSG from "../../../utils/messages";
import "./selectField.css";

type TProps = {
  label: string;
  onChangeHandler: React.ChangeEventHandler<HTMLSelectElement>;
  options: string[];
  value: string | number;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
};
const SelectField = (props: TProps) => {
  const {
    label,
    onChangeHandler,
    value,
    required = false,
    disabled = false,
    errorMessage,
    options,
  } = props;
  const [isFocusedOut, setIsFocusedOut] = useState(false);

  return (
    <div className="pulse-select-container">
      <div className="pulse-select-label">
        {label} {required && <span>*</span>}
      </div>
      <select
        className={`pulse-select-field ${
          errorMessage || (required && isFocusedOut && !value)
            ? "select-error"
            : ""
        }`}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        onBlur={!isFocusedOut ? () => setIsFocusedOut(true) : undefined}
      >
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="select-error-msg">
        {errorMessage
          ? errorMessage
          : !value && required && isFocusedOut
          ? MSG.REQUIRED
          : ""}
      </div>
    </div>
  );
};

export default SelectField;
