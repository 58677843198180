type TPath = {
  label: string;
  path: string;
};
const PATHS: { [key: string]: TPath } = {
  LOGIN: { label: "/", path: "" },
  REGISTER: { label: "register", path: "/register" },
  PULSE: { label: "pulse", path: "/pulse" }
};

export default PATHS;
