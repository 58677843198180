import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import DetailsCard from "./DetailsCard";

const ORG_FIELDS = [
  "planType",
  "active",
  "validFrom",
  "validTill",
  "createdAt",
  "planId",
  "licenseId"
];
const LicenseDetails = () => {
  const licenseDetails = useAppSelector((state) => state.user.licenseProfile);
  

  return (
    <div className="org-details-container">
        <div className="profile-title">License Details</div>
      {licenseDetails && (
        <DetailsCard data={licenseDetails} fields={ORG_FIELDS} />
      )}
    </div>
  );
};

export default LicenseDetails;
