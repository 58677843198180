import { MdKeyboardArrowRight } from "react-icons/md";

export const getViewDetailsColumnConfig = () => {
    return {
      field: "View Details",
      headerName: "View details",
      cellRenderer: () => (
        <div className="details-col">
          <div className="arrow-btn">
            <MdKeyboardArrowRight />
          </div>
        </div>
      ),
      pinned: "right",
      cellStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "font-size": "20px",
      },
      width: 115,
    };
  };
  
//Column map for some expected columns
export const ColumnMap: {[key: string]: any}= {
    username: "User Name",
    emailid: "Email",
    contactno: "Contact",
    userrole: "Role",
    createdat: "Created At"
}