import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLoginDetails } from "./storeModel";

interface TAuthState {
  uid: string;
  email: string;
  accessToken: string;
  isLogggedIn: boolean;
  loginError: string;
  photoUrl: string;
}

const INITIAL_STATE: TAuthState = {
  uid: "",
  email: "",
  accessToken: "",
  isLogggedIn: false,
  loginError: "",
  photoUrl: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthState: (state) => {
      state = {...INITIAL_STATE};
      return state;
    },
    setLoginDetails: (state, action: PayloadAction<TLoginDetails>) => {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
      state.photoUrl = action.payload.photoUrl;
      state.isLogggedIn = true;
      state.loginError = "";
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state = { ...INITIAL_STATE, loginError: action.payload };
      return state;
    },
  },
});

export const { resetAuthState, setLoginDetails, setLoginError } = authSlice.actions;

export default authSlice.reducer;
