import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TUser } from "../../store/storeModel";
import TableComponent from "../../Components/common/TableComponent";
import { TableType } from "./manageUserUtils";
import InviteUser from "./InviteUserForm";
import InviteCompany from "./InviteCompanyForm";

type TProps = {
  data: TUser[];
  title: string;
  isSuccess: boolean;
  columns?: string[];
  type: TableType;
};
const UserList = (props: TProps) => {
  const { data, title, columns = [], type } = props;
  //TODO: Add title
  //TODO: Handle if error case
  //TODO: handle if data is empty case
  return (
    <div className="user-section-container">
      <div className="user-top-bar">
        <div className="user-section-title">{title}</div>
        {type === TableType.USER_INVITES && <InviteUser />}
        {type === TableType.COMPANY_INVITES && <InviteCompany />}
      </div>
      <div>
        <TableComponent rowData={data as any} columns={columns} />
      </div>
    </div>
  );
};

export default UserList;
