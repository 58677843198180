import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TCluster } from "./storeModel";
import _ from "lodash";
import { getMonthUsageSummary } from "../api";

interface TPlanBillingData {
  usage: number;
  isLoaded: boolean;
}

const initialState: TPlanBillingData = {
  usage: 0,
  isLoaded: false,
};

export const fetchCurrentUsage = createAsyncThunk(
  "planBilling/fetchCurrentUsage",
  async () => {
    const response = await getMonthUsageSummary()
    console.log(response);
    if (response.data) {
      return response.data.data;
    }
    throw "Invalid response";
  }
);

const planBillingSlice = createSlice({
  name: "planBilling",
  initialState,
  reducers: {
    resetBillingPlanningdata: (state) => {
      state = _.cloneDeep(initialState);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCurrentUsage.fulfilled,
      (state, action: PayloadAction<number>) => {
        console.log(action.payload)
        //TODO: Update or finish this function
      }
    );
  },
});

export const { resetBillingPlanningdata } = planBillingSlice.actions;

export default planBillingSlice.reducer;
