import CurrentUsage from "./CurrentUsage";
import LastMonthBill from "./LastMonthBill";

import "./billing.css";

const Billing = () => {
  return (
    <div className="billing-section-container">
      <div className="billing-title">Billing and Invoices</div>
      <div className="billing-description">Description about billing and invoices</div>
      <div className="billing-card-section">
       <CurrentUsage/>
       <LastMonthBill/>
      </div>
    </div>
  );
};

export default Billing;
