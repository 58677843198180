import { useState } from "react";
import MSG from "../../../utils/messages";
import "./inputField.css";

type TProps = {
  label: string;
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
  value: string | number;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  type?: string;
};
const InputField = (props: TProps) => {
  const {
    label,
    onChangeHandler,
    value,
    required = false,
    disabled = false,
    errorMessage,
    type
  } = props;
  const [isFocusedOut, setIsFocusedOut] = useState(false);

  return (
    <div className="pulse-input-container">
      <div className="pulse-input-label">
        {label} {required && <span>*</span>}
      </div>
      <input
        className={`pulse-input-field ${
          errorMessage || (required && isFocusedOut && !value)
            ? "input-error"
            : ""
        }`}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        onBlur={!isFocusedOut ? () => setIsFocusedOut(true) : undefined}
        type={type}
      />
      <div className="input-error-msg">
        {errorMessage
          ? errorMessage
          : !value && required && isFocusedOut
          ? MSG.REQUIRED
          : ""}
      </div>
    </div>
  );
};

export default InputField;
