import { MdQuestionAnswer } from "react-icons/md";
import "./infoCard.css";

type TProps = {
  title: string;
  children: React.ReactNode;
  icon: React.ReactElement;
};
const InfoCard = (props: TProps) => {
  const { title, children, icon } = props;
  return (
    <div className="infocard-container">
      <div className="infocard-title">
        {icon} <div>{title}</div>
      </div>
      <div className="infocard-child-container">{children}</div>
    </div>
  );
};

export default InfoCard;
