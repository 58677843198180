const URLS = {
  GET_CURRENT_USER: "/spike/user/current",
  UPDATE_USER_PROFILE: "/spike/user/update",
  GET_USER_LIST: "/spike/user/list",
  GET_USER_INVITES: "/spike/user/invite/list",
  GET_COMPANY_INVITES: "/spike/company/invite/list",
  GET_CLUSTER_LIST: "/spike/cluster/list",
  GET_CLUSTER_DETAILS: "/spike/cluster/get/",
  GET_USAGE_SUMMARY: "/spike/usage/summary",
  REGISTER_USER: "/spike/user/register", //TODO: Check if register by invite is required
  NOTIFY_SUPPORT_TEAM: "/spike/plan/subscribe",
  INVITE_USER: "/spike/user/invite",
  INVITE_COMPANY: "/spike/company/invite"
};

export default URLS;
