export enum PLAN_TYPES {
  ON_PREM = "On-prem Edition",
  ENTERPRISE = "Enterprise Edition",
}
export enum TPlanIds {
  MGD = "MGD-1",
  SELF = "SELF-1",
}
export interface TNotifyPayload {
  planId: TPlanIds;
}
export interface TPlanList {
  type: PLAN_TYPES;
  planId: TPlanIds;
  price: string;
  description: string;
  actionLabel: string;
  featureList: string[];
}

export const PLAN_LIST: TPlanList[] = [
  {
    type: PLAN_TYPES.ON_PREM,
    planId: TPlanIds.SELF,
    price: "0.06",
    description: "Self hosted plan description if any",
    actionLabel: "Subscribe to on-prem",
    featureList: [
      "Unlimited logs ingestion",
      "Unlimited retention of logs",
      "Unlimited users",
      "Unlimited search queries",
      "Unlimited live tail of logs",
      "Unlimited log alerts",
      "Slack and email support",
    ],
  },
  {
    type: PLAN_TYPES.ENTERPRISE,
    planId: TPlanIds.MGD,
    price: "",
    description:
      "Enterprise edition includes all features from on-prem edition along with additional features mentioned below.",
    actionLabel: "I am interested",
    featureList: [
      "Clusters deployed and operated by Episilia team",
      "Priority support",
    ],
  },
];
