import { THEME } from "./commonConstants";

export const validateWebsiteUrl = (url: string): boolean => {
  const regex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return regex.test(url);
};

export const validateEmail = (email:string) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
}

export const getTheme = () => {
  const theme = localStorage.getItem(THEME.THEME_KEY);
  if (theme) {
    return theme;
  }
  return THEME.LIGHT;
};

export const setTheme = (theme: string) => {
  localStorage.setItem(THEME.THEME_KEY, theme);
};

export const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult => 
  input.status === 'rejected'

export const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> => 
  input.status === 'fulfilled'