import episiliaLogo from "../../assets/images/Final-Logo.png";
import { FcGoogle } from "react-icons/fc";
import "./login.css";
import { showPopup } from "../../firebaseConfig";
import { useAppSelector } from "../../store/hooks";
import { Navigate } from "react-router-dom";
import PATHS from "../../utils/path";

const Login = () => {
  const isLogggedIn = useAppSelector((state) => state.auth.isLogggedIn);
  const isRegistered = useAppSelector((state) => state.user.isRegistered);

  const loginOrRegister = () => {
    showPopup();
  };

  if (isLogggedIn && isRegistered) {
    return <Navigate to={PATHS.PULSE.path} />;
  }

  if (isLogggedIn) {
    return <Navigate to={PATHS.REGISTER.path} />;
  }

  return (
    <div className="login-container">
      <div className="login-section">
        <div className="login-image-container">
          <img src={episiliaLogo} alt="epislia-logo" />
        </div>
        <div className="tag-line">Power of logs at your fingertips</div>
        <div className="google-login" onClick={loginOrRegister}>
          <FcGoogle />
          <span>Login with Google</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
