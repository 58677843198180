import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import PATHS from "../../../utils/path";

type TProps = {
  children: React.ReactNode;
  path: string;
};

const PrivateRoute = (props: TProps): JSX.Element => {
  const { children, path } = props;
  const isLogggedIn = useAppSelector((state) => state.auth.isLogggedIn);
  const isRegistered = useAppSelector((state) => state.user.isRegistered);
  if (!isLogggedIn) {
    return <Navigate to="/" />;
  } else if (!isRegistered && path !== PATHS.REGISTER.label) {
    return <Navigate to={PATHS.REGISTER.path} />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRoute;
