// Import the functions you need from the SDKs you need
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  resetAuthState,
  setLoginDetails,
  setLoginError,
} from "./store/AuthSlice";
import { resetAllStore, store } from "./store";
import { resetInitialLoader } from "./store/LoaderSlice";
import { fetchCurrentUser, resetUserdetails } from "./store/UserSlice";
import { resetManageUserDetails } from "./store/ManageUserSlice";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const showPopup = async () => {
  await signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const refreshToken = credential.refreshToken;
      // The signed-in user info.
      const user = result.user;
      const uid = user.uid;
      const email = user.email;
      const accessToken = user.accessToken;
      const photoUrl = user.photoURL;
      store.dispatch(setLoginDetails({ uid, email, accessToken, photoUrl }));
      store.dispatch(fetchCurrentUser());
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      //   const errorCode = error.code;
      // The email of the user's account used.
      //   const email = error.customData.email;
      // The AuthCredential type that was used.
      //   const credential = GoogleAuthProvider.credentialFromError(error);
      const errorMessage = error.message;
      store.dispatch(setLoginError(errorMessage));
    });
};

export const onPageRefresh = async () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const accessToken = user.accessToken;
      const email = user.email;
      const uid = user.uid;
      const photoUrl = user.photoURL;
      store.dispatch(setLoginDetails({ uid, email, accessToken, photoUrl }));
      await store.dispatch(fetchCurrentUser());
    } else {
      store.dispatch(setLoginError("Invalid login details"));
    }
    store.dispatch(resetInitialLoader());
  });
};
onPageRefresh();

export const logout = () => {
  signOut(auth)
    .then(() => {
      resetAllStore();
    })
    .catch(() => {
      //TODO: show message modal
    });
};
