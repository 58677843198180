import { useLocation } from "react-router-dom";
import _ from "lodash";
import WizardConfig from "./wizardConfig";

const TitleComponent = () => {
  const location = useLocation();
  const getLabel = () => {
    const wizardItem = _.find(WizardConfig, { path: location.pathname });
    return wizardItem?.title;
  };
  return <div className="wizard-content-title">{getLabel()}</div>;
};

export default TitleComponent;
