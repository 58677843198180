import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { getClusterDetails } from "../../api";
import _ from "lodash";
import TableComponent from "../../Components/common/TableComponent";
import { BiArrowBack } from "react-icons/bi";

type TProps = {
  clusterId: number;
  setShowDetailsPage: Function;
  setSelectedId: Function;
};
const ClusterDetails = (props: TProps) => {
  const { clusterId, setSelectedId, setShowDetailsPage } = props;
  const [data, setData] = useState<{ [key: string]: any[] }>({});
  const dispatch = useDispatch();

  const loadClusterDetails = async () => {
    try {
      dispatch(setLoader());
      const response = await getClusterDetails(clusterId);
      if (response.data) {
        setData(response.data);
      }
      //TODO: Handle error case
    } catch (error) {
    } finally {
      dispatch(resetLoader());
    }
  };

  useEffect(() => {
    loadClusterDetails();
  }, [clusterId]);

  const onBack = () => {
    setShowDetailsPage(false);
    setSelectedId(null);
  };
  return (
    <div className="cluster-detail-container">
      <div className="back-button" onClick={onBack}>
        <BiArrowBack />
        Back
      </div>
      <div className="cluster-title">Cluster details</div>
      <div>
        {_.keys(data).map((key: string, index) => {
          return (
            <div className="cluster-item-container">
              <div className="cluster-subtitle">{_.startCase(key)}</div>
              <div>
                <TableComponent rowData={data[key]} columns={[]} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClusterDetails;
