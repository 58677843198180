import { configureStore } from "@reduxjs/toolkit";
import authReducer, { resetAuthState } from "./AuthSlice";
import loaderReducer, { resetLoaderSlice } from "./LoaderSlice";
import userReducer, { resetUserdetails } from "./UserSlice";
import themeReducer from "./ThemeSlice";
import manageUserReducer, { resetManageUserDetails } from "./ManageUserSlice";
import clusterReducer, { resetClusterData } from "./ClusterSlice";
import planBillingReducer, {
  resetBillingPlanningdata,
} from "./PlanBillingSlice";
import modalReducer from "./ModalSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    user: userReducer,
    theme: themeReducer,
    manageUser: manageUserReducer,
    clusterData: clusterReducer,
    planBilling: planBillingReducer,
    modal: modalReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {auth: TAuthState}
export type AppDispatch = typeof store.dispatch;

//Add store reset function incase of logout
export const resetAllStore = () => {
  store.dispatch(resetAuthState());
  store.dispatch(resetUserdetails());
  store.dispatch(resetManageUserDetails());
  store.dispatch(resetClusterData());
  store.dispatch(resetBillingPlanningdata());
};
