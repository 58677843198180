import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentUserDetails } from "../api";
import {
  TCompanyProfile,
  TCurrentUserPayload,
  TLicenseProfile,
  TUserProfile,
} from "./storeModel";

interface TUserState {
  isRegistered: boolean;
  isSubscribed: boolean;
  userProfile: TUserProfile | null;
  licenseProfile: TLicenseProfile | null;
  companyProfile: TCompanyProfile | null;
}

const INITIAL_STATE: TUserState = {
  isRegistered: false,
  isSubscribed: false,
  userProfile: null,
  licenseProfile: null,
  companyProfile: null,
};

export const fetchCurrentUser = createAsyncThunk(
  "user/fetchCurrentUser",
  async () => {
    const response = await getCurrentUserDetails();
    if (response.data) {
      return response.data.data;
    }
    throw "Invalid response";
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    resetUserdetails: (state) => {
      state = { ...INITIAL_STATE };
      return state;
    },
    updateUserProfile: (state, action: PayloadAction<TUserProfile>) => {
      state.userProfile = action.payload;
    },
    updateCurrentUserData: (state, action: PayloadAction<TCurrentUserPayload>) => {
      state.isRegistered = action.payload.registered;
      state.isSubscribed = action.payload.subscribed;
      state.companyProfile = action.payload.companyProfile ?? null;
      state.licenseProfile = action.payload.licenseProfile ?? null;
      state.userProfile = action.payload.userProfile ?? null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCurrentUser.fulfilled,
      (state, action: PayloadAction<TCurrentUserPayload>) => {
        state.isRegistered = action.payload.registered;
        state.isSubscribed = action.payload.subscribed;
        state.companyProfile = action.payload.companyProfile ?? null;
        state.licenseProfile = action.payload.licenseProfile ?? null;
        state.userProfile = action.payload.userProfile ?? null;
      }
    );
  },
});

export const { resetUserdetails, updateUserProfile, updateCurrentUserData } = userSlice.actions;
export default userSlice.reducer;
