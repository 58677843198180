export const UserListColumns = [
  "username",
  "emailid",
  "contactno",
  "userrole",
  "active",
  "createdat",
];

export interface TInviteUserPayload {
  emailId: string;
  userRole: string;
}

export type TInviteUserPayloadKey = keyof TInviteUserPayload;

export interface TInviteCompanyPayload {
  companyId: string;
  userRole: string;
  validDomain: string;
}

export type TInviteCompanyPayloadKey = keyof TInviteCompanyPayload;

export enum TableType {
  USER_INVITES = "USER_INVITES",
  COMPANY_INVITES = "COMPANY_INVITES",
  USERS_LIST = "USERS_LIST",
}

export const USER_ROLES = ["admin", "Devops"];
