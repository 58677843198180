import { MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Button from "../Button";
import "./modal.css";
import {hideModal} from "../../../store/ModalSlice";

const MessageModal = () => {
  const { showModal, message } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const cancelModal = ()=>{
    dispatch(hideModal())
  }
//   TODO: uncomment this
  if (!showModal) {
    return null;
  }
  return (
    <div className="modal-container">
      <div className="modal-inner">
        <div className="msg-section">{message}</div>
        <div className="btn-section">
            <Button label={"Ok"} onClick={cancelModal} classList={["modal-btn"]}/>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
