import { MdDataUsage } from "react-icons/md";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { fetchCurrentUsage } from "../../store/PlanBillingSlice";
import { useDispatch } from "react-redux";
import { store } from "../../store";

const CurrentUsage = () => {
  const { isLoaded } = useAppSelector((state) => state.planBilling);

  useEffect(() => {
    if (!isLoaded) {
      store.dispatch(fetchCurrentUsage());
    }
  }, [isLoaded]);

  return (
    <div className="current-usage-container">
      <div className="cu-left">
        <div className="cu-title">
          <MdDataUsage />
          <div>Current memory usage</div>
        </div>
        <div className="cu-details">
          <div>Usage details</div> <HiArrowNarrowRight />
        </div>
      </div>
      <div className="cu-right">
        <div>
          500<span className="cu-unit">GB</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentUsage;
