import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

interface TLoaderState {
  showLoader: boolean;
  initialLoader: boolean;
  loaderCount: number;
}

const INITIAL_STATE: TLoaderState = {
  showLoader: false,
  initialLoader: true,
  loaderCount: 0,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState: INITIAL_STATE,
  reducers: {
    resetLoaderSlice: (state) => {
      state = _.clone(INITIAL_STATE);
      return state;
    },
    setLoader: (state) => {
      state.showLoader = true;
      state.loaderCount = state.loaderCount + 1;
    },
    resetLoader: (state) => {
      if (state.loaderCount === 1) {
        state.showLoader = false;
      }
      if (state.loaderCount > 0) {
        state.loaderCount = state.loaderCount - 1;
      }
    },
    setInitialLoader: (state) => {
      state.initialLoader = true;
    },
    resetInitialLoader: (state) => {
      state.initialLoader = false;
    },
  },
});

export const {
  setLoader,
  resetLoader,
  setInitialLoader,
  resetInitialLoader,
  resetLoaderSlice,
} = loaderSlice.actions;

export default loaderSlice.reducer;
