import { MouseEvent, ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Button from "../Button";
import "./commonModal.css";
import { hideModal } from "../../../store/ModalSlice";
import { MdOutlineClose } from "react-icons/md";

type TProps = {
  showModal: boolean;
  children: ReactElement;
  onClose: Function;
};
const CommonModal = (props: TProps) => {
  const dispatch = useAppDispatch();
  const { showModal, children, onClose } = props;

  const cancelModal = () => {
    dispatch(hideModal());
  };
  //   TODO: uncomment this
  if (!showModal) {
    return null;
  }
  return (
    <div className="common-modal-container">
      <div className="common-modal-inner">
        <div onClick={() => onClose()} className="close-btn">
          <MdOutlineClose />
        </div>
        {children}
      </div>
    </div>
  );
};

export default CommonModal;
