import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getTheme, setTheme } from "../utils/helperFunctions";

interface TTheme {
  theme: string;
}

const initialState: TTheme = {
  theme: getTheme(),
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setPageTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
      setTheme(action.payload);
    },
  },
});

export const { setPageTheme } = themeSlice.actions;

export default themeSlice.reducer;