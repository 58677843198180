import axios from "axios";
import { store } from "../store";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const pulseApi = axios.create({
  baseURL,
});

pulseApi.interceptors.request.use(
  (config) => {
    const authState = store.getState().auth;
    config.headers["auth-token"] = authState.accessToken;
    config.headers["email"] = authState.email;
    config.headers["uid"] = authState.uid;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
