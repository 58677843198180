import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import PATHS from "../../utils/path";
import "./registration.css";
import episiliaLogo from "../../assets/images/Final-Logo.png";
import RegistrationForm from "./RegistrationForm";



const Registration = () => {
  const isRegistered = useAppSelector((state) => state.user.isRegistered);

//   If registered user will be redirected to dashboard
  if (isRegistered) {
    return <Navigate to={`../${PATHS.PULSE.path}`} />;
  }

  return (
    <div className="registration-container">
      <div className="registration-content">
        <div className="login-image-container">
          <img src={episiliaLogo} alt="epislia-logo" />
        </div>
        <div className="registration-head-section">
          <div className="registration-title">Get Started</div>
          <div className="registration-sub-title">
            Please enter details below to register your company
          </div>
        </div>
        <RegistrationForm/>
      </div>
    </div>
  );
};

export default Registration;
