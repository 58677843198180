import { MouseEvent, useState, Fragment, ChangeEvent } from "react";
import Button from "../../Components/common/Button";
import CommonModal from "../../Components/common/CommonModal";
import SelectField from "../../Components/common/SelectField";
import {
  TInviteUserPayload,
  TInviteUserPayloadKey,
  USER_ROLES,
} from "./manageUserUtils";
import InputField from "../../Components/common/InputField";
import _ from "lodash";
import MESSAGES from "../../utils/messages";
import { validateEmail } from "../../utils/helperFunctions";
import { useAppDispatch } from "../../store/hooks";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { inviteUser } from "../../api";
import { showModal } from "../../store/ModalSlice";
import { updateInviteList } from "../../store/ManageUserSlice";

const INITIAL_STATE: TInviteUserPayload = {
  emailId: "",
  userRole: USER_ROLES[0],
};

const INITIAL_ERROR_STATE: TInviteUserPayload = {
  emailId: "",
  userRole: "",
};

const InviteUser = () => {
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [userDetails, setUserDetails] =
    useState<TInviteUserPayload>(INITIAL_STATE);
  const [errorObj, setErrorObj] =
    useState<TInviteUserPayload>(INITIAL_ERROR_STATE);
  const dispatch = useAppDispatch();

  const onUserDetailUpdate = (key: TInviteUserPayloadKey, value: string) => {
    const updatedState: TInviteUserPayload = { ...userDetails, [key]: value };
    setUserDetails(updatedState);
    const errorMsg = validateValue(key, value);
    setErrorObj({ ...errorObj, [key]: errorMsg });
  };

  const isFormValid = () => {
    const updatedErrorObj: TInviteUserPayload = { ...INITIAL_ERROR_STATE };
    let key: TInviteUserPayloadKey;
    let isValid = true;
    for (key in userDetails) {
      let msg = validateValue(key, userDetails[key]);
      if (msg) {
        updatedErrorObj[key] = msg;
        isValid = false;
      }
    }
    setErrorObj(updatedErrorObj);
    return isValid;
  };

  const onSubmit = async () => {
    const isValid = isFormValid();
    if (isValid) {
      try {
        dispatch(setLoader());
        setShowInviteModal(false);
        const response: any = await inviteUser(userDetails);
        if (!response.data.success) {
          throw "Failed to send invitation";
        }
        await dispatch(updateInviteList());
        dispatch(
          showModal(response.data.message ?? "Invitation sent successfully!!!")
        );
      } catch (e) {
        dispatch(showModal("Failed to send invitation"));
      } finally {
        dispatch(resetLoader());
      }
    }
  };
  return (
    <Fragment>
      <Button
        label={"+ Invite user"}
        onClick={() => setShowInviteModal(true)}
        classList={["invite-btn"]}
      />
      <CommonModal
        showModal={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      >
        <div>
          <div className="invite-user-title">Invite User</div>
          <InputField
            label={"Email"}
            onChangeHandler={(e) =>
              onUserDetailUpdate("emailId", e.target.value)
            }
            value={userDetails.emailId}
            errorMessage={errorObj.emailId}
          />
          <SelectField
            label={"User role"}
            onChangeHandler={(e) =>
              onUserDetailUpdate("userRole", e.target.value)
            }
            value={userDetails.userRole}
            options={USER_ROLES}
            errorMessage={errorObj.userRole}
          />
          <Button label={"Send Request"} onClick={onSubmit} />
        </div>
      </CommonModal>
    </Fragment>
  );
};

export default InviteUser;

const validateValue = (key: TInviteUserPayloadKey, value: string): string => {
  let msg = "";
  if (_.isEmpty(value)) {
    msg = MESSAGES.REQUIRED;
  } else if (key === "emailId" && !validateEmail(value)) {
    msg = MESSAGES.INVALID_EMAIL;
  }
  return msg;
};
