import { MdOutlineCheck } from "react-icons/md";
import { PLAN_TYPES, TPlanList, TNotifyPayload, TPlanIds } from "./planConfig";
import Button from "../../Components/common/Button";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { resetLoader, setLoader } from "../../store/LoaderSlice";
import { showModal } from "../../store/ModalSlice";
import { notifySupportTeam } from "../../api";

type TProps = {
  config: TPlanList;
};

const PlanCard = (props: TProps) => {
  const { config } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const paymentRedirection = () => {};

  const contactSupport = async (planId: TPlanIds) => {
    //TODO: Call subscribe api
    try {
      dispatch(setLoader());
      const payload: TNotifyPayload = {
        planId: planId,
      };
      const response: any = await notifySupportTeam(payload);
      console.log(response);
      if (!response.data.success) {
        throw "Failed to send email";
      }
      dispatch(showModal(response.data.message));
    } catch (error) {
      dispatch(showModal("Something went wrong. Please try again later"));
    } finally {
      dispatch(resetLoader());
    }
  };

  return (
    <div className="plan-card-conatiner">
      <div className="pc-head-section">
        <div className="pc-title">{config.type}</div>
        <div className="pc-description">{config.description}</div>
      </div>
      {config.type === PLAN_TYPES.ON_PREM && (
        <div className="pc-price">
          ${config.price} <span className="pc-price-unit">/GB</span>
        </div>
      )}
      {config.type === PLAN_TYPES.ENTERPRISE && (
        <div className="contact-msg">Contact us for more details</div>
      )}
      <div className="features-section">
        <div className="features-title">
          {config.type === PLAN_TYPES.ON_PREM
            ? "Features"
            : "Additional features"}
        </div>
        <div>
          {config.featureList.map((feature) => {
            return (
              <div className="feature-item">
                <MdOutlineCheck />
                <div>{feature}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Button
          label={config.actionLabel}
          onClick={() => contactSupport(config.planId)}
        />
      </div>
    </div>
  );
};

//TODO: Add purchase flow
//TODO: Add mail has been send flow
export default PlanCard;
