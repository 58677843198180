import { MdDataUsage } from "react-icons/md";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { fetchCurrentUsage } from "../../store/PlanBillingSlice";
import { useDispatch } from "react-redux";
import { store } from "../../store";
import { useNavigate } from "react-router-dom";

const LastMonthBill = () => {
  const navigate = useNavigate();

  const onPayBill = ()=>{
    console.log("hello")
    navigate("../payment");
  }
  
  return (
    <div className="current-usage-container">
      <div className="cu-left">
        <div className="cu-title">
          <MdDataUsage />
          <div>Last month bill</div>
        </div>
        <div className="cu-details" onClick={()=>onPayBill()}>
          <div>Pay bill</div> <HiArrowNarrowRight />
        </div>
      </div>
      <div className="cu-right">
        <div>
          <span className="cu-unit">$</span>60
        </div>
      </div>
    </div>
  );
};

export default LastMonthBill;
