import { validateWebsiteUrl } from "../../utils/helperFunctions";
import { FIELD_NAMES, REG_FIELDS, REG_KEY } from "./registrationModel";
import MSG from "../../utils/messages";
import _ from "lodash";

export const INITIAL_STATE: REG_FIELDS = {
  name: "",
  email: "",
  mobile: "",
  company: "",
  companyUrl: "",
};

export const isInputValid = (value: string, key: REG_KEY): string => {
  let errorMessage: string = "";
  if (_.isEmpty(value)) {
    errorMessage = MSG.REQUIRED;
  } else if (
    _.isEqual(key, FIELD_NAMES.COMPANY_URL) &&
    !validateWebsiteUrl(value)
  ) {
    errorMessage = MSG.INVALID_URL;
  }
  return errorMessage;
};

export const getErrorObject = (
  registrationDetails: REG_FIELDS
): { errorObject: REG_FIELDS; isValid: boolean } => {
  const errorObject = _.cloneDeep(INITIAL_STATE);
  let isValid = true;
  let key: REG_KEY;
  for (key in registrationDetails) {
    const errorMessage = isInputValid(registrationDetails[key], key);
    if (errorMessage) {
      isValid = false;
      errorObject[key] = errorMessage;
    }
  }
  return { errorObject, isValid };
};
