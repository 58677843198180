import { TInviteCompanyPayload, TInviteUserPayload } from "../Containers/ManageUsers/manageUserUtils";
import { TNotifyPayload } from "../Containers/Plans/planConfig";
import { TRegistrationPayload } from "../Containers/Registration/registrationModel";
import { TUpdateUserPayload } from "../utils/commonModal";
import URLS from "./UrlConstants";
import { pulseApi } from "./config";

export const getCurrentUserDetails = () => {
  return pulseApi.get(URLS.GET_CURRENT_USER);
};

export const updateUser = (payload: TUpdateUserPayload) => {
  return pulseApi.post(URLS.UPDATE_USER_PROFILE, payload);
};

export const getUsersList = () => {
  return pulseApi.get(URLS.GET_USER_LIST);
};

export const getUserInvitesList = () => {
  return pulseApi.get(URLS.GET_USER_INVITES);
};

export const getCompanyInvitesList = () => {
  return pulseApi.get(URLS.GET_COMPANY_INVITES);
};

export const getClusterList = () => {
  return pulseApi.get(URLS.GET_CLUSTER_LIST);
};

export const getClusterDetails = (id: number) => {
  return pulseApi.get(URLS.GET_CLUSTER_DETAILS + id);
};

export const getMonthUsageSummary = () => {
  return pulseApi.get(URLS.GET_USAGE_SUMMARY);
};

export const registerUser = (payload: TRegistrationPayload) => {
  return pulseApi.post(URLS.REGISTER_USER, payload);
};

export const notifySupportTeam = (payload: TNotifyPayload) => {
  return pulseApi.post(URLS.NOTIFY_SUPPORT_TEAM, payload);
};

export const inviteUser = (payload: TInviteUserPayload) => {
  return pulseApi.post(URLS.INVITE_USER, payload);
};

export const inviteCompany = (payload: TInviteCompanyPayload) => {
  return pulseApi.post(URLS.INVITE_COMPANY, payload);
};