import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  TManageUser,
  TManageUserListTypes,
  TManageUserPayload,
  TUser,
} from "./storeModel";
import { getUserInvitesList, getUsersList } from "../api";
import _ from "lodash";

const initialState: TManageUser = {
  isUserListLoaded: false,
  isCompanyInvitesLoaded: false,
  isInviteListLoaded: false,
  userList: [],
  invitedUsers: [],
  companyInvites: [],
  isExecuted: false,
};
export const updateInviteList = createAsyncThunk(
  "manageUser/updateInviteList",
  async () => {
    const response = await getUserInvitesList();
    if (response.data && response.data.success) {
      return response.data.data;
    }
    throw "Invalid response";
  }
);

const manageUserSlice = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    resetManageUserDetails: (state) => {
      state = _.cloneDeep(initialState);
      return state;
    },
    setInitialLoadedData: (
      state,
      action: PayloadAction<TManageUserPayload[]>
    ) => {
      const updatedState = _.cloneDeep(state);
      updatedState.isExecuted = true;

      let item: TManageUserPayload;
      for (item of action.payload) {
        switch (item.type) {
          case TManageUserListTypes.UserList:
            updatedState.isUserListLoaded = item.isSuccess;
            updatedState.userList = item.data;
            break;
          case TManageUserListTypes.InviteList:
            updatedState.isInviteListLoaded = item.isSuccess;
            updatedState.invitedUsers = item.data;
            break;
          case TManageUserListTypes.CompanInviteList:
            updatedState.isCompanyInvitesLoaded = item.isSuccess;
            updatedState.companyInvites = item.data;
            break;
        }
      }
      return updatedState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateInviteList.fulfilled,
      (state, action: PayloadAction<TUser[]>) => {
        state.invitedUsers = action.payload;
      }
    );
  },
});

export const { resetManageUserDetails, setInitialLoadedData } =
  manageUserSlice.actions;

export default manageUserSlice.reducer;
