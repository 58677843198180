import _ from 'lodash';
type TProps = {
  fields: string[];
  data: { [key: string]: string | number };
};

const DetailsCard = (props: TProps) => {
  const { fields, data } = props;

  return (
    <div className="detail-list">
      {fields.map((field: string) => {
        return (
          <div className="details-item">
            <div className="detail-title">{_.startCase(field)}</div>
            <div className="details-value">{data[field]}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DetailsCard;
