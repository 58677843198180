import ContactDetails from "./ContactDetails";
import Documentation from "./Documentation";
import "./support.css";

const Support = () => {
  return (
    <div className="support-container">
      <Documentation />
      <ContactDetails />
    </div>
  );
};

export default Support;
