import { MdModeEdit } from "react-icons/md";
import { useAppSelector } from "../../store/hooks";
import DetailsCard from "./DetailsCard";
import UserEditForm from "./UserEditForm";
import { useState, Fragment } from "react";

const USER_FIELDS = [
  "userName",
  "userId",
  "userRole",
  "emailId",
  "contactNo",
  "createdAt",
  "updatedAt",
];
const UserDetails = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile);
  const [isEditing, setIsEditing] = useState(false);
  const cancelEditMode = ()=>setIsEditing(false)

  return (
    <div className="user-details-container">
      <div className="profile-title">User Details</div>
      {!isEditing ? (
        <Fragment>
          <div className="edit-icon-container" onClick={()=>setIsEditing(true)}>
            <MdModeEdit />
          </div>
          {userProfile && (
            <DetailsCard data={userProfile} fields={USER_FIELDS} />
          )}
        </Fragment>
      ) : (
        <UserEditForm cancelEditMode={cancelEditMode}/>
      )}
    </div>
  );
};

export default UserDetails;
